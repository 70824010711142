import App from "./App.vue";
import { VueFire, VueFireAuth } from "vuefire";
import { firebaseApp } from "./firebase";

import { createApp } from "vue";
import * as Sentry from "@sentry/vue";

import vuetify from "./vuetify";
import pinia from "./store";
import router from "./router";
import "core-js/features/array/to-sorted";
import "unfonts.css";

import messages from "@intlify/unplugin-vue-i18n/messages";
import { createI18n } from "vue-i18n";

const i18n = createI18n({
  locale: localStorage.getItem("locale") || navigator.language.split("-")[0] || "en",
  fallbackLocale: "en",
  messages,
});

console.log(i18n.global.messages);

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://963243079a0a2c75b3cb0d9f7c6a3ddf@o4506735387410432.ingest.sentry.io/4506735388786688",
  integrations: [Sentry.browserTracingIntegration({ router })],
  tracesSampleRate: 1.0,
  enabled: import.meta.env.PROD,
  environment: import.meta.env.VITE_ENV || "production",
});

app
  .use(vuetify)
  .use(router)
  .use(pinia)
  .use(VueFire, {
    firebaseApp,
    modules: [VueFireAuth()],
  })
  .use(i18n)
  .mount("#app");
