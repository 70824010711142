import { getApp, initializeApp, type FirebaseOptions } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
  updateDoc,
  writeBatch,
  type DocumentData,
  type FirestoreDataConverter,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import type { CreateClientParams } from "@aircap/functions/src/callables/createClient";
import type { SendAuthLinkParams } from "@aircap/functions/src/callables/sendAuthLink";
import type { CreateProjectParams } from "@aircap/functions/src/callables/createProject";
import type { SendSwanOnboardingLinkParams } from "@aircap/functions/src/callables/sendSwanOnboardingLink";
import type { GetPowensLinkParams } from "@aircap/functions/src/callables/getPowensLink";
import type { DeleteUserParams } from "@aircap/functions/src/callables/deleteUser";
export const converter = <T extends DocumentData>(): FirestoreDataConverter<T> => {
  return {
    toFirestore(data: T): DocumentData {
      return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<T>): T {
      return snapshot.data();
    },
  };
};

const firebaseConfig: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);

const functions = getFunctions(getApp(), "europe-west1");

if (import.meta.env.DEV) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

const auth = getAuth(firebaseApp);
auth.useDeviceLanguage();

export const db = getFirestore(firebaseApp);

// export const projecstRef = collection(db, "projects").withConverter(converter<Project>());

export interface ClientData {
  name: string;
}

interface Doc {
  id: string;
}

export interface Client extends ClientData, Doc {}

export const getProject = async (projectId: string) => {
  const docRef = doc(db, "projects", projectId);
  const docSnap = await getDoc(docRef);

  return docSnap.data();
};

export const getItem = async (uuid: string) => {
  const ref = collection(db, "bridgeItems");

  const q = query(ref, where("user_uuid", "==", uuid));
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs[0];
};

export const getProjectAccounts = async (projectId: string) => {
  const docRef = doc(db, "projects", projectId);

  return getDocs(collection(docRef, "bridgeAccounts"));
};

export const setAccountId = async ({
  projectId,
  accountId,
}: {
  projectId: string;
  accountId: string;
}) => {
  const docRef = doc(db, "projects", projectId);
  updateDoc(docRef, {
    accountId,
  });
};

/** Permet à l'admin de créer un client */
export const inviteClient = async ({ email, name }: { email: string; name: string }) => {
  const batch = writeBatch(db);

  const clientRef = doc(collection(db, "clients"));
  const inviteRef = doc(collection(db, "clientInvites"));

  batch.set(clientRef, { name });
  batch.set(inviteRef, { email, clientId: clientRef.id });

  return batch.commit();
};

const callGetBridgeLink = httpsCallable<{ projectId: string }, string>(functions, "getBridgeLink");
const callResendAuthLink = httpsCallable<SendAuthLinkParams, string>(functions, "resendAuthLink");
const callCreateClient = httpsCallable<CreateClientParams, void>(functions, "createClient");
const callCreateProject = httpsCallable<CreateProjectParams, string>(functions, "createProject");
const callSendSwanOnboardingLink = httpsCallable<SendSwanOnboardingLinkParams, void>(
  functions,
  "sendSwanOnboardingLink",
);
const callDeleteUser = httpsCallable<DeleteUserParams, void>(functions, "deleteUser");
const callGetPowensLink = httpsCallable<GetPowensLinkParams, string>(functions, "getPowensLink");

export const createClient = (p: CreateClientParams) => {
  return callCreateClient(p);
};
export const createProject = (p: CreateProjectParams) => {
  return callCreateProject(p).then((result) => result.data);
};

export const getBridgeConnectLink = (p: { projectId: string }) =>
  callGetBridgeLink(p).then((result) => result.data);

export const getPowensConnectLink = (p: GetPowensLinkParams) =>
  callGetPowensLink(p).then((result) => result.data);

export const resendAuthLink = (p: SendAuthLinkParams) =>
  callResendAuthLink(p).then((result) => result.data);

export const sendSwanOnboardingLink = (p: SendSwanOnboardingLinkParams) =>
  callSendSwanOnboardingLink(p);

export const deleteUser = (p: DeleteUserParams) => callDeleteUser(p);
